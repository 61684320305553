@charset "utf-8";


// As hex codes */

$color-primary-0: #065E7B;	// Main Primary color */
$color-primary-1: #33829D;
$color-primary-2: #137798;
$color-primary-3: #034C65;
$color-primary-4: #01384A;
$color-primary-5: #d8ecf3;

$color-secondary-1-0: #C58C02;	// Main Secondary color (1) */
$color-secondary-1-1: #f4f3f1;
$color-secondary-1-2: #F3B215;
$color-secondary-1-3: #A17200;
$color-secondary-1-4: #775400;

$color-secondary-2-0: #C52102;	// Main Secondary color (2) */
$color-secondary-2-1: #FB674B;
$color-secondary-2-2: #F33815;
$color-secondary-2-3: #A11900;
$color-secondary-2-4: #771200;




// Generated by Paletton.com Â© 2002-2014 */
// http://paletton.com */


// Define defaults for each variable.

// $base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-family: ff-meta-web-pro, ff-meta-web-pro-1, sans-serif !default;
$header-font-family: ff-meta-web-pro, ff-meta-web-pro-1, sans-serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       $color-primary-4 !default;
$background-color: lighten($color-primary-5, 7%) !default;
$background-color-content: #fdfdfd !default;
$brand-color:      $color-secondary-2-4 !default;
$emphasis-color:   $color-primary-3 !default;

$grey-color:       #555 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

$std-img-width:  18.75em !default;
$std-img-max-height: 18.75em !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
header{
  // background-color:  $color-primary-5;
  
}
header .wrapper a {
  color:  $color-primary-3;
}

.page-content .wrapper{
  background-color:  $background-color-content;
}
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/_base",
  "minima/_syntax-highlighting"
;

// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=33p0u0kvCoHmnvstbuuA+kdJXeW */

// Feel free to copy&paste color codes to your application */


.paginator
{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    
    clear: both;
    //background-color: $color-primary-2;
}

.paginator li {
    float: left;    
}

.paginator li a {
    display: block;
    text-align: center;
    padding: 0.2em;
    text-decoration: none;
    color: $color-secondary-2-4;
}


.paginator a {  
  text-align: center;
  text-decoration: none;
  color: $color-secondary-2-4;
}

.newerpostllink{
  float:left;
}
.olderpostlink{
  float:right;  
}

.paginator a:hover {
    background-color: $color-primary-5;
}
.pagelogo{
  float: left;
  display: block;
  height: $base-line-height * $base-font-size * 2.25;
  margin: 0;
  padding: 0;
  height: $spacing-unit * 1.865; //$base-line-height * $base-font-size * 2.25;//54px;
  //width: ($spacing-unit * 1.865)*1.85; //100px
}
@media screen and (max-height: 400px) {
  .pagelogo{
    float: left;  
    display:block;  
    //height: $base-line-height * $base-font-size * 2.25;
  }
}

.logo{
  float: left;
  display: block;
  margin-left: -$spacing-unit;
  @include media-query($on-palm) {
  margin-left: -$spacing-unit/2
  }
  @include media-query($on-laptop) {
    margin-left: -$spacing-unit/2
  }
}

.logo img{
  padding-right: 5px;
}
article a {
  font-weight: bold;
}
/**
 * Site header
 */
 .site-header {
  // border-top: 5px solid $grey-color-dark;
  // border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;
  background-color: #01384A;
  background-image: linear-gradient(-179.5deg, $text-color, lighten($text-color, 7.5%));
  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;    
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $background-color;
    line-height: $base-line-height;
    font-size:small;
    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;
      color: $text-color;
      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }   
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
  
}

.footer-fine-print{  
  @include relative-font-size(.5);
}


.footer-notice{
  text-align: center;
  @include relative-font-size(.675);
}

.footer-notice a {
  text-decoration: underline
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}


.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {  
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
    clear:both;
  }

  .headshot{
   
    width: $std-img-width;
    float:right;
    clear:right;    
    @include media-query($on-palm) {
      float:none;
      clear:both;
      display:block;        
      img
      { 
          max-height: $std-img-max-height;      
      }
    }
    margin-left: 1.25em;
    margin-top: 0;
    margin-right: 0.5em;
    margin-bottom: .75em;
    > figcaption {
      font-size: xx-small;
    }
  }

}

.rss-subscribe{
  text-align: center;
}
  
.more-link, .more-link:visited {
  display: inline;
  color: $color-primary-0;
  font-style: italic;
  clear:both;

  margin-top: -1em;
  padding-right: 1em;
  text-align: center;
}

.standalone_image{       
  width: $std-img-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;     
  margin-left: 1.25em;
  margin-top: 0;
  margin-right: 0.5em;
  margin-bottom: .75em;
  > figcaption {
    font-size: xx-small;
    text-align: left;
    width: 100%;
    margin-left: 1.25em;
  }    
  @include media-query($on-palm) {
    img
    { 
        max-height: $std-img-max-height;      
    }    
  }

  .big_image {
    @extend .standalone_image;
    width: 100%;
  }
}

.gallery
{
  flex-direction: row;
  justify-content: center;
  align-items: center;     
  width:100%;
  .gallery_image {       
    width: $std-img-width;
    display: inline-block;
    clear: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;     
    margin-left: 1.25em;
    margin-top: 0;
    margin-right: 0.5em;
    margin-bottom: .75em;
    > figcaption {
      font-size: xx-small;
      text-align: left;
      width: 100%;
      margin-left: 1.25em;
    }
    @include media-query($on-palm) {
      img
      { 
          max-height: $std-img-max-height;      
      }    
    } 
  }
}


.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;  
  font-family: $header-font-family;
  @include relative-font-size(1.5);
}

h1, h2, h3, h4 {
  font-family: $header-font-family;
  @include relative-font-size(1.75);
}

  .headshot{
   
    width: $std-img-width;
    float:right;
    clear:right;    
    @include media-query($on-palm) {
      float:none;
      clear:both;
      display:block;        
      img
      { 
          max-height: $std-img-max-height;      
      }
    }
    margin-left: 1.25em;
    margin-top: 0;
    margin-right: 0.5em;
    margin-bottom: .75em;
    > figcaption {
      font-size: xx-small;
    }
  }

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;
  font-family: $header-font-family;
  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }

}

.rule-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: small;
  color:$emphasis-color;
  border-top-width: .1em;
  border-top-style: solid;
  border-bottom-width: .1em;
  border-bottom-style: solid;
  li{
    $indent: 4em;
    margin-left: 1em;
    text-indent: $indent * -1;
    padding-left: $indent;
  }
}

.link-menu-wrapper{
  position:relative;
}
.link-menu {
  
  
  margin: 0;
  line-height: normal;
  font-size: small;
  color:$emphasis-color;
  border-top-width: .1em;
  border-top-style: solid;
  border-bottom-width: .1em;
  border-bottom-style: solid;
  background-color: $background-color;
}
.link-list {
  list-style-type: none;

  li {    
    margin-bottom: 1em;
    .link-description {
      display:inline-block;      
    }
  }
  .subtitle {    
    font-style: italic;
  }
  .author {
    font-size: smaller;
  }
}

.note {
  margin: 1em;
  background-color: $grey-color-dark;
  color:white;
  display:block;
}

.rule-full-list{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.rule-num {
  font-weight: 900;
}

.column {
  float: left;
  width: 33.33%;
  
}
.column ul {
  font-size: .8em;
  padding: 1em;
}
.column ul:after {
  content: "";
  display: table;
  clear: both;      
}

.column h4, ul, li {
  line-height: 1.2;
  margin:0;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

ul li {
  padding-left: 10px;
}

.unadorned-list {
  margin-left: 0;
  list-style: none;
  margin-block-end: 1em;
}

.flipH {
  transform: scale(-1, 1);
  display: inline-block;
}